/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Lodash from 'lodash';
import { Formik, Field } from 'formik';
import { VectorMap } from '@south-paw/react-vector-maps';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Button } from '@material-ui/core';
import * as Yup from 'yup';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import Loading from '../../Components/Loading/Loading';
import style from './styles';
import NationalGraphic from './NationalGraphic';
import costaRica from './costaRica.json';

import { PlnDropdown } from '../../Components/Input';
import {
  useLoadMain,
  getSumatoriaVotosbyProvincia2,
  getSumatoriaVotosbyCantonProvincia,
  getSumatoriaVotosbyDistritoCantonProvincia,
} from '../../Core/Api/convencionApi';

import {
  getCantonsByProvince,
  getDistritoByTwo,
} from '../../Core/Api/locationApi';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

const Map = styled.div(props => ({
  margin: '1rem auto',
  width: '100%',

  svg: {
    stroke: '#999',

    // All layers are just path elements
    path: {
      fill: '#B6D166',
      cursor: 'pointer',
      outline: 'none',

      // When a layer is hovered
      '&:hover': {
        fill: 'rgba(0, 0, 0, 0.6)',
      },

      // When a layer is focused.
      '&:focus': {
        fill: 'rgba(168, 43, 43, 0.6)',
      },

      // When a layer is 'checked' (via checkedLayers prop).
      '&[aria-checked="true"]': {
        fill: 'rgba(56, 43, 168, 1)',
      },

      // When a layer is 'selected' (via currentLayers prop).
      '&[aria-current="true"]': {
        fill: 'rgba(56, 43, 168, 0.83)',
      },

      // Guanacaste
      '&[id="cr-g"]': {
        fill: props.crG,
        // When a layer is hovered
        '&:hover': {
          fill: 'rgba(0, 0, 0, 0.6)',
        },
      },

      // Laguna
      '&[id="cr-la"]': {
        fill: props.crG,
        stroke: 'none',
      },

      // Alajuela
      '&[id="cr-a"]': {
        fill: props.crA,
        // When a layer is hovered
        '&:hover': {
          fill: 'rgba(0, 0, 0, 0.6)',
        },
      },

      // Heredia
      '&[id="cr-h"]': {
        fill: props.crH,
        // When a layer is hovered
        '&:hover': {
          fill: 'rgba(0, 0, 0, 0.6)',
        },
      },

      // Limon
      '&[id="cr-l"]': {
        fill: props.crL,
        // When a layer is hovered
        '&:hover': {
          fill: 'rgba(0, 0, 0, 0.6)',
        },
      },

      // Puntarenas
      '&[id="cr-p"]': {
        fill: props.crP,
        // When a layer is hovered
        '&:hover': {
          fill: 'rgba(0, 0, 0, 0.6)',
        },
      },

      // San Jose
      '&[id="cr-sj"]': {
        fill: props.crSJ,
        // When a layer is hovered
        '&:hover': {
          fill: 'rgba(0, 0, 0, 0.6)',
        },
      },

      // Cartago
      '&[id="cr-c"]': {
        fill: props.crC,
        // When a layer is hovered
        '&:hover': {
          fill: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const MainPage = props => {
  const { classes } = props;
  const { data } = useLoadMain();

  const [showFilters] = useState(true);
  const mainData = Lodash.get(data, ['mainData'], []);

  // Lista de: Provincias, Cantones, Distritos y Movimientos
  const [initialValues] = useState({
    provincia: 9,
    canton: 999,
    distrito: 999,
  });

  // const [isDataEmpty, setDataEmpty] = useState(true);

  // console.log(jrvProcesadas);
  /*
  const [hovered, setHovered] = React.useState('None');
  const [focused, setFocused] = React.useState('None');
  */
  const [
    provinciaClicked,
    setProvinciaClicked,
  ] = React.useState('None');
  const [openProvincia, setOpenProvincia] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [codProvincia, setCodProvincia] = useState(1);
  const [
    ganadorProvincia,
    setGanadorProvincia,
  ] = useState();

  const handleClose = () => {
    setOpenProvincia(false);
  };

  const [dataToShow, setDataToShow] = useState({});

  const [cantones, setCantones] = useState([
    { itemLabel: '- SELECCIONE EL CANTÓN-', value: 999 },
  ]);
  const [distritos, setDistritos] = useState([
    { itemLabel: '- SELECCIONE EL DISTRITO-', value: 999 },
  ]);

  // Opciones Seleccionadas
  const [sCanton, setSCanton] = useState(999);
  // eslint-disable-next-line no-unused-vars
  const [sDistrito, setSDistrito] = useState(999);

  const changeProvincia = p => {
    setCantones([
      {
        itemLabel: '- SELECCIONE EL CANTÓN-',
        value: 999,
      },
    ]);
    setDistritos([
      {
        itemLabel: '- SELECCIONE EL DISTRITO-',
        value: 999,
      },
    ]);
    initialValues.provincia = p;
    initialValues.canton = 999;
    initialValues.distrito = 999;
    if (p !== 9) {
      getCantonsByProvince(p).then(result => {
        if (result.status === 200) {
          const cantonsList = Lodash.get(result, [
            'data',
            'response',
          ]);

          const clist = cantonsList.map(
            ({ cod_canton, des_nombre_c }) => {
              return {
                itemLabel: des_nombre_c,
                value: cod_canton,
              };
            },
          );
          clist.unshift({
            itemLabel: '- SELECCIONE EL CANTÓN-',
            value: 999,
          });

          setCantones(clist);
        }
      });
    }
  };

  const setCanton = c => {
    initialValues.distrito = 999;
    initialValues.provincia = codProvincia;
    initialValues.canton = c;
    initialValues.movimiento = 9;

    if (c !== 999) {
      getDistritoByTwo(codProvincia, c).then(result => {
        if (result.status === 200) {
          const distritosList = Lodash.get(result, [
            'data',
            'response',
          ]);
          const dlist = distritosList.map(
            ({ cod_distrito, des_nombre_d }) => {
              return {
                itemLabel: des_nombre_d,
                value: cod_distrito,
              };
            },
          );
          dlist.unshift({
            itemLabel: '- SELECCIONE EL DISTRITO-',
            value: 999,
          });

          setSCanton(
            Lodash.find(cantones, {
              value: c,
            }),
          );

          setDistritos(dlist);
        }
      });

      getSumatoriaVotosbyCantonProvincia(
        codProvincia,
        c,
      ).then(nResult => {
        setDataToShow(
          nResult.data.votosByCantonProvincia[0],
        );
      });
    } else {
      setDistritos([
        {
          itemLabel: '- SELECCIONE EL DISTRITO-',
          value: 999,
        },
      ]);
    }
  };

  const setDistrito = d => {
    initialValues.provincia = codProvincia;
    initialValues.canton = sCanton.value;
    initialValues.distrito = d;
    if (d !== 999) {
      getSumatoriaVotosbyDistritoCantonProvincia(
        codProvincia,
        sCanton.value,
        d,
      ).then(nResult => {
        setDataToShow(
          nResult.data.votosByDistritoCantonProvincia[0],
        );
      });
      setSDistrito(
        Lodash.find(distritos, {
          value: d,
        }),
      );
    } else {
      setSDistrito({ itemLabel: '', value: 0 });
    }
  };

  const refreshModal = () => {
    setIsRefreshing(true);
    getSumatoriaVotosbyProvincia2(codProvincia).then(
      nResult => {
        setDataToShow(nResult.data.votosByProvincia[0]);
        changeProvincia(codProvincia);
        setIsRefreshing(false);
      },
    );
  };

  const openProvinciaDetail = provincia => {
    setProvinciaClicked(provincia);
    const dataProvincia = Lodash.find(
      mainData.votosByProvincia,
      {
        provincia,
      },
    );
    setCodProvincia(dataProvincia.codProvincia);
    changeProvincia(dataProvincia.codProvincia);
    setDataToShow(dataProvincia);
    setGanadorProvincia(dataProvincia.num_ganador);
    if (dataProvincia.jrvProcesadas > 0) {
      setOpenProvincia(true);
    } else {
      setOpenProvincia(false);
    }
  };

  const layerProps = {
    onClick: ({ target }) =>
      openProvinciaDetail(target.attributes.name.value),
  };

  const validationSchema = Yup.object({});

  const roundN = (num, n) => {
    return num
      .toFixed(n)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const getTimeStamp = currentDate => {
    const now = new Date(currentDate);
    // console.log(now.getHours());
    return `${now.getHours()}:${
      now.getMinutes() < 10
        ? `0${now.getMinutes()}`
        : now.getMinutes()
    }`;
  };

  // Date Options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // timeZone: 'UTC',
  };

  const formatDate = date => {
    const dateParts = date.split('/');
    const month = dateParts[1];
    const day = dateParts[0];
    const year = dateParts[2];

    return [day, month, year].join('/');
  };

  Object.size = obj => {
    let size = 0;
    // const key = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  // console.log(getTimeStamp());

  MainPage.propTypes = {};
  if (!Lodash.isEmpty(mainData)) {
    return (
      <>
        <Container
          maxWidth={false}
          className={classes.pageBox}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
          >
            <Grid
              item
              xs={6}
              style={{
                backgroundColor: '#fff',
                padding: 25,
                textAlign: 'center',
              }}
            >
              <Map
                crG={
                  mainData.votosByProvincia[4]
                    .jrvProcesadas !== 0
                    ? `${mainData.votosByProvincia[4].color_ganador}`
                    : 'rgba(256, 256, 256, 1)'
                }
                crA={
                  mainData.votosByProvincia[1]
                    .jrvProcesadas !== 0
                    ? `${mainData.votosByProvincia[1].color_ganador}`
                    : 'rgba(256, 256, 256, 1)'
                }
                crH={
                  mainData.votosByProvincia[3]
                    .jrvProcesadas !== 0
                    ? `${mainData.votosByProvincia[3].color_ganador}`
                    : 'rgba(256, 256, 256, 1)'
                }
                crL={
                  mainData.votosByProvincia[6]
                    .jrvProcesadas !== 0
                    ? `${mainData.votosByProvincia[6].color_ganador}`
                    : 'rgba(256, 256, 256, 1)'
                }
                crP={
                  mainData.votosByProvincia[5]
                    .jrvProcesadas !== 0
                    ? `${mainData.votosByProvincia[5].color_ganador}`
                    : 'rgba(256, 256, 256, 1)'
                }
                crSJ={
                  mainData.votosByProvincia[0]
                    .jrvProcesadas !== 0
                    ? `${mainData.votosByProvincia[0].color_ganador}`
                    : 'rgba(256, 256, 256, 1)'
                }
                crC={
                  mainData.votosByProvincia[2]
                    .jrvProcesadas !== 0
                    ? `${mainData.votosByProvincia[2].color_ganador}`
                    : 'rgba(256, 256, 256, 1)'
                }
              >
                <VectorMap
                  {...costaRica}
                  layerProps={layerProps}
                />
              </Map>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.rightColumnData}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  float: 'right',
                  padding: 10,
                  marginRight: 10,
                  marginTop: 10,
                }}
                className={classes.buttonRefresh}
                onClick={() => {
                  window.location = './';
                }}
              >
                <RefreshIcon />
              </Button>
              <h2>
                CORTE N° {mainData.numCorte} | Fecha:{' '}
                {formatDate(
                  new Date(
                    mainData.fec_corte,
                  ).toLocaleDateString('es-ES', options),
                )}{' '}
                - Hora: {getTimeStamp(mainData.fec_corte)}{' '}
              </h2>
              <span
                style={{ display: 'block', fontSize: 22 }}
              >
                <div
                  style={{
                    float: 'left',
                  }}
                >
                  <strong>
                    JRV Procesadas:{' '}
                    <span style={{ color: '#008945' }}>
                      {mainData.jrvProcesadas}
                    </span>{' '}
                    de{' '}
                    <span style={{ color: '#008945' }}>
                      {mainData.totalJRV}
                    </span>
                  </strong>
                </div>
                <div
                  style={{
                    float: 'left',
                    marginLeft: '40px',
                    marginBottom: 20,
                    color: '#84BF48',
                  }}
                >
                  <strong>
                    (
                    {parseInt(mainData.totalJRV, 10) !== 0
                      ? (
                          (parseInt(
                            mainData.jrvProcesadas,
                            10,
                          ) *
                            100) /
                          parseInt(mainData.totalJRV, 10)
                        ).toFixed(2)
                      : 0}
                    %)
                  </strong>
                </div>
              </span>

              <TableContainer
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Candidato</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>
                          Cantidad de Votos:{' '}
                          {mainData.totalVotosNac}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mainData.votosNacionales.map(row => (
                      <TableRow key={row.candidato}>
                        <TableCell
                          component="th"
                          scope="row"
                        >
                          <span
                            style={{
                              display: 'block',
                              float: 'left',
                              width: '15px',
                              height: '15px',
                              backgroundColor:
                                row.backgroundColor,
                              borderColor: row.borderColor,
                              marginRight: 5,
                            }}
                          >
                            &nbsp;
                          </span>
                          {row.candidato}
                        </TableCell>
                        <TableCell align="right">
                          {roundN(row.votos, 0)}{' '}
                          <div
                            style={{
                              float: 'right',
                              width: 70,
                            }}
                          >
                            ({row.porcentaje}%)
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <NationalGraphic chartData={mainData.chart} />
            </Grid>
          </Grid>

          <Dialog
            disableBackdropClick
            fullWidth
            maxWidth="lg"
            open={openProvincia}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.customDialog}
          >
            <DialogContent>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    padding: 25,
                    textAlign: 'center',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '1.8rem',
                      fontWeight: 'bold',
                      wordWrap: 'normal',
                      position: 'absolute',
                      color: '#008945',
                      top: 15,
                      left: 0,
                      right: 0,
                      transform: 'translateX(-25%)',
                    }}
                  >
                    Provincia de {provinciaClicked}
                  </div>
                  <img
                    src={`./${provinciaClicked}${ganadorProvincia}.png`}
                    alt={provinciaClicked}
                    style={
                      provinciaClicked !== 'Heredia'
                        ? { width: '80%', height: 'auto' }
                        : {}
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    padding: 5,
                    textAlign: 'left',
                  }}
                >
                  <Button
                    variant="contained"
                    color={
                      isRefreshing ? 'secondary' : 'primary'
                    }
                    style={{
                      float: 'right',
                      padding: 10,
                    }}
                    className={classes.buttonRefresh}
                    onClick={refreshModal}
                  >
                    {isRefreshing ? (
                      <CircularProgress size={25} />
                    ) : (
                      <RefreshIcon />
                    )}
                  </Button>
                  <h2
                    style={{
                      marginTop: 0,
                      marginBottom: 10,
                    }}
                  >
                    CORTE N° {mainData.numCorte} | Fecha:{' '}
                    {formatDate(
                      new Date(
                        mainData.fec_corte,
                      ).toLocaleDateString(
                        'es-ES',
                        options,
                      ),
                    )}{' '}
                    - Hora:{' '}
                    {getTimeStamp(mainData.fec_corte)}
                  </h2>
                  <span
                    style={{
                      display: 'block',
                      fontSize: 22,
                    }}
                  >
                    <div
                      style={{
                        float: 'left',
                      }}
                    >
                      <strong>
                        JRV Procesadas:{' '}
                        <span style={{ color: '#008945' }}>
                          {dataToShow.jrvProcesadas}
                        </span>{' '}
                        de{' '}
                        <span style={{ color: '#008945' }}>
                          {dataToShow.jrvTotal}
                        </span>
                      </strong>
                    </div>
                    <div
                      style={{
                        float: 'left',
                        marginLeft: '40px',
                        marginBottom: 10,
                        color: '#84BF48',
                      }}
                    >
                      <strong>
                        (
                        {parseInt(
                          dataToShow.jrvTotal,
                          10,
                        ) !== 0
                          ? (
                              (parseInt(
                                dataToShow.jrvProcesadas,
                                10,
                              ) *
                                100) /
                              parseInt(
                                dataToShow.jrvTotal,
                                10,
                              )
                            ).toFixed(2)
                          : 0}
                        %)
                      </strong>
                    </div>
                  </span>
                  {Object.size(dataToShow) > 0 && (
                    <TableContainer
                      style={{
                        marginTop: '20px',
                        marginBottom: '20px',
                      }}
                    >
                      <Table
                        className={classes.tableModal}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <strong>Candidato</strong>
                            </TableCell>
                            <TableCell align="right">
                              <strong>
                                Cantidad de Votos:{' '}
                                {dataToShow.totalVotos}
                              </strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataToShow.votos.map(row => (
                            <TableRow key={row.candidato}>
                              <TableCell
                                component="th"
                                scope="row"
                              >
                                <span
                                  style={{
                                    display: 'block',
                                    float: 'left',
                                    width: '15px',
                                    height: '15px',
                                    backgroundColor:
                                      row.backgroundColor,
                                    borderColor:
                                      row.borderColor,
                                    marginRight: 5,
                                  }}
                                >
                                  &nbsp;
                                </span>
                                {row.candidato}
                              </TableCell>
                              <TableCell align="right">
                                {roundN(row.votos, 0)}{' '}
                                <div
                                  style={{
                                    float: 'right',
                                    width: 70,
                                  }}
                                >
                                  ({row.porcentaje}%)
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  {Object.size(dataToShow) > 0 && (
                    <NationalGraphic
                      chartData={dataToShow.chartData}
                    />
                  )}
                  {showFilters && (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                    >
                      <div className={classes.boxForm}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Field
                              name="canton"
                              data={cantones}
                              component={PlnDropdown}
                              onChange={canton =>
                                setCanton(canton)
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              name="distrito"
                              data={distritos}
                              component={PlnDropdown}
                              onChange={distrito =>
                                setDistrito(distrito)
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Formik>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
                className={classes.btnActions}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </>
    );
  }
  return (
    <Container maxWidth={false} className={classes.pageBox}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          style={{
            padding: 50,
            textAlign: 'center',
          }}
        >
          <Loading label="Cargando" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default withStyles(style)(MainPage);
