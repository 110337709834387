const styles = () => ({
  pageBox: {
    root: { backgroundColor: '#fff' },
    backgroundColor: '#fff',
    padding: 0,
    maxWidth: 1400,
  },

  rightColumnData: {
    backgroundColor: '#fff',
    padding: 25,
    textAlign: 'left',
  },

  table: {
    minWidth: 650,
    border: 'none',
  },

  tableModal: {
    border: 'none',
  },

  customDialog: {
    '& .MuiPaper-root': {
      backgroundColor: 'rgba(256, 256, 256, 1)',
      border: 'solid 5px #84BF48',
    },
    '& .MuiDialog-paperWidthLg': {
      maxWidth: '1480px',
    },
  },

  boxForm: {
    marginTop: 10,
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
    },
  },
});

export default styles;
