import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Divider,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import style from './styles';

const Header = props => {
  const { classes } = props;

  return (
    <Container maxWidth={false}>
      <Grid
        container
        alignItems="center"
        className={classes.root}
      >
        <img
          src="/logo2020.png"
          className={classes.imgLogo}
          alt="LogoPLN"
        />
        <Divider
          orientation="vertical"
          flexItem
          style={{ margin: 15 }}
        />
        <h1 className={classes.mainTitlePage}>
          Convención Interna 2021
        </h1>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <AppBar position="static">
          <Toolbar style={{ minHeight: '10px' }}>
            &nbsp;
          </Toolbar>
        </AppBar>
      </Grid>
    </Container>
  );
};

export default withStyles(style)(Header);
