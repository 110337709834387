/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const NationalGraphic = props => {
  const { chartData } = props;

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        label: chartData.datasets.label,
        data: chartData.datasets.data,
        backgroundColor: chartData.datasets.backgroundColor,
        borderColor: chartData.datasets.borderColor,
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Bar data={data} options={options} />
    </>
  );
};

NationalGraphic.propTypes = {
  chartData: PropTypes.instanceOf(Object).isRequired,
};
export default NationalGraphic;
