import Home from '../Containers/Home/Home';
import MainPage from '../Containers/MainPage/MainPage';

const routesList = [
  {
    auth: false,
    component: MainPage,
    exact: true,
    name: 'mainPage',
    path: '/',
  },
  {
    auth: true,
    component: Home,
    exact: true,
    name: 'home',
    path: '/home',
  },
];

export default routesList;
