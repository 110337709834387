/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { useQuery } from 'react-query';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

export const getJRVProcesadas = () => {
  const url = [`/convencion/getJRVProcesadas`];
  return axiosInstance.get(url.join('&'));
};

export const getTotalJRV = () => {
  const url = [`/convencion/getTotalJRV`];
  return axiosInstance.get(url.join('&'));
};

export const getSumatoriaVotosNacionales = () => {
  const url = [`/convencion/getSumatoriaVotosNacionales`];
  return axiosInstance.get(url.join('&'));
};

export const getSumatoriaVotosbyProvincia = () => {
  const url = [`/convencion/getSumatoriaVotosbyProvincia`];
  return axiosInstance.get(url.join('&'));
};

export const getSumatoriaVotosbyProvincia2 = codProvincia => {
  const url = [
    `/convencion/getSumatoriaVotosbyProvincia2/${codProvincia}`,
  ];
  return axiosInstance.get(url.join('&'));
};

export const getSumatoriaVotosbyCantonProvincia = (
  codProvincia,
  codCanton,
) => {
  const url = [
    `/convencion/getSumatoriaVotosbyCantonProvincia/${codProvincia}/${codCanton}`,
  ];
  return axiosInstance.get(url.join('&'));
};

export const getSumatoriaVotosbyDistritoCantonProvincia = (
  codProvincia,
  codCanton,
  codDistrito,
) => {
  const url = [
    `/convencion/getSumatoriaVotosbyDistritoCantonProvincia/${codProvincia}/${codCanton}/${codDistrito}`,
  ];
  return axiosInstance.get(url.join('&'));
};

/*
const searchJrv = jrv => {
  return axiosInstance.get(`convencion/searchJrv/${jrv}`);
};

const saveResultados = _info => {
  const data = {
    resultados: _info,
  };
  return axiosInstance.post(
    'convencion/saveResultados',
    data,
  );
};
*/

const getDataItems = async data => {
  /*
  const frmOptions = data.map(p => {
    return {
      itemLabel: p.des_nombre_p,
      value: p.cod_provincia,
    };
  });

  frmOptions.unshift({
    itemLabel: '- SELECCIONE LA PROVINCIA -',
    value: 9,
  });

  const initValues = {
    provincia: 9,
    canton: 999,
    distrito: 999,
    papeleta: 999,
    jrv: 999,
  };
  */
  const jrvProcesadas = await getJRVProcesadas();
  const totalJRV = await getTotalJRV();
  const votosNacionales = await getSumatoriaVotosNacionales();
  const votosByProvincia = await getSumatoriaVotosbyProvincia();

  const allData = {
    ...data[0],

    jrvProcesadas:
      jrvProcesadas.data.response[0].procesadas,

    totalJRV: totalJRV.data.response[0].total,
    votosNacionales: votosNacionales.data.tableData,
    totalVotosNac: votosNacionales.data.totalVotos,
    chart: votosNacionales.data.chartData,
    votosByProvincia:
      votosByProvincia.data.votosByProvincia,
  };

  return {
    mainData: allData,
  };
};

export const getMainData = async () => {
  try {
    const url = [`/convencion/getNumUltimoCorte`];

    const { data } = await axios.get(url.join('&'));
    const mainData = getDataItems(data.response);

    return mainData;
  } catch (e) {
    return { mainData: {} };
  }
};

export const useLoadMain = () => {
  return useQuery(['mainData'], () => getMainData());
};
